const STATE_PREFIX = 'state';
export const STATE = {
  SHOW_ERROR: `${STATE_PREFIX}/SHOW_ERROR`,
  CLOSE_ERROR: `${STATE_PREFIX}/CLOSE_ERROR`,
  LOADING: `${STATE_PREFIX}/LOADING`,
  LOADED: `${STATE_PREFIX}/LOADED`,
  FREEZE_USER_ACCOUNT: `${STATE_PREFIX}/FREEZE_USER_ACCOUNT`,
  INCREASE_NEWS_PAGE: `${STATE_PREFIX}/INCREASE_NEWS_PAGE`,
  SET_ROUTE_ACTIVE: `${STATE_PREFIX}/SET_ROUTE_ACTIVE`,
};

const AUTH_PREFIX = 'auth';
export const AUTH = {
  LOGIN: `${AUTH_PREFIX}/LOGIN`,
  FB_LOGIN: `${AUTH_PREFIX}/FB_LOGIN`,
  GOOGLE_LOGIN: `${AUTH_PREFIX}/GOOGLE_LOGIN`,
  RECOVERY_PASSWORD: `${AUTH_PREFIX}/RECOVERY_PASSWORD`,
  CREATE: `${AUTH_PREFIX}/CREATE`,
  PROFILE_CREATE: `${AUTH_PREFIX}/PROFILE_CREATE`,
  UPDATE_USER: `${AUTH_PREFIX}/UPDATE_USER`,
  REGISTER_ACCESS: `${AUTH_PREFIX}/REGISTER_ACCESS`,
  TOKEN_DEAD: `${AUTH_PREFIX}/TOKEN_DEAD`,
  SET_USER_FLAG: `${AUTH_PREFIX}/SET_USER_FLAG`,
  UPDATE_LOCAL_USER: `${AUTH_PREFIX}/UPDATE_LOCAL_USER`,
  LOGOUT: `${AUTH_PREFIX}/LOGOUT`,
};

const WAYPOINT_PREFIX = 'waypoint';
export const WAYPOINT = {
  LOAD_COLLECTION: `${WAYPOINT_PREFIX}/LOAD_COLLECTION`,
  LOAD_USER_COLLECTION: `${WAYPOINT_PREFIX}/USER_COLLECTION`,
  LOAD: `${WAYPOINT_PREFIX}/LOAD`,
};

const WAYPOINT_TYPE_PREFIX = 'waypoint_types';
export const WAYPOINT_TYPE = {
  LOAD_COLLECTION: `${WAYPOINT_TYPE_PREFIX}/LOAD_COLLECTION`,
  LOAD: `${WAYPOINT_TYPE_PREFIX}/LOAD`,
};

const ROUTE_PREFIX = 'route';
export const ROUTE = {
  LOAD_COLLECTION: `${ROUTE_PREFIX}/LOAD_COLLECTION`,
  LOAD: `${ROUTE_PREFIX}/LOAD`,
  CREATE: `${ROUTE_PREFIX}/CREATE`,
  UPDATE: `${ROUTE_PREFIX}/UPDATE`,
  DELETE: `${ROUTE_PREFIX}/DELETE`,
  CLEAR: `${ROUTE_PREFIX}/CLEAR`,
  INVERT: `${ROUTE_PREFIX}/INVERT`,
  SPLIT: `${ROUTE_PREFIX}/SPLIT`,

  ADD_WAYPOINT: `${ROUTE_PREFIX}/ADD_WAYPOINT`,
  UPDATE_WAYPOINT: `${ROUTE_PREFIX}/UPDATE_WAYPOINT`,
  SWITCH_WAYPOINT: `${ROUTE_PREFIX}/SWITCH_WAYPOINT`,
  SORT_WAYPOINT: `${ROUTE_PREFIX}/SORT_WAYPOINT`,
  REMOVE_WAYPOINT: `${ROUTE_PREFIX}/REMOVE_WAYPOINT`,
  HIGHLIGHT_WAYPOINT: `${ROUTE_PREFIX}/HIGHLIGHT_WAYPOINT`,
  FADE_WAYPOINT: `${ROUTE_PREFIX}/FADE_WAYPOINT`,
  UPDATE_ALL: `${ROUTE_PREFIX}/UPDATE_ALL`,
};

const ROUTE_WAYPOINT_PREFIX = 'route_waypoint';
export const ROUTE_WAYPOINT = {
  LOAD_COLLECTION: `${ROUTE_WAYPOINT_PREFIX}/LOAD_COLLECTION`,
  LOAD: `${ROUTE_WAYPOINT_PREFIX}/LOAD`,
};

const FEEDBACK_PREFIX = 'feedback';
export const FEEDBACK = {
  SEND: `${FEEDBACK_PREFIX}/SEND`,
  RESET_STATE: `${FEEDBACK_PREFIX}/RESET_STATE`,
};

const NEWS_PREFIX = 'news';
export const NEWS = {
  LOAD_COLLECTION: `${NEWS_PREFIX}/LOAD_COLLECTION`,
  UPDATE_LAST_NEWS_ID: `${NEWS_PREFIX}/UPDATE_LAST_NEWS_ID`,
};

const PLAN_PREFIX = 'plan';
export const PLAN = {
  LOAD_COLLECTION: `${PLAN_PREFIX}/LOAD_COLLECTION`,
};

const DATA_UPDATE_PREFIX = 'data_update';
export const DATA_UPDATE = {
  LOAD_LAST: `${DATA_UPDATE_PREFIX}/LOAD_LAST`,
};

const DATA_UPDATE_ITEM_PREFIX = 'data_update_items';
export const DATA_UPDATE_ITEM = {
  LOAD_COLLECTION: `${DATA_UPDATE_ITEM_PREFIX}/LOAD_COLLECTION`,
};
