import React from 'react';
import CheckBox from 'react-uwp/CheckBox';
import './MapOptions.style.scss';

import { useTourContext } from 'context/TourContext/TourContext';

import metrics from '../../../../utils/metrics';

const MapOptions = props => {
  const tourContext = useTourContext();

  return (
    <div className="map__options">
      <h1 className="map__options__title">Cartas Aeronáuticas</h1>

      <span className="map__options__item">
        <CheckBox
          className="map__options__item__checkbox"
          defaultChecked={props.chartOptionsFirst === 'REA'}
          value="REA"
          onCheck={() => {
            props.onChartOptionsFirst('REA');
            metrics.collect(metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.CARTA, 'REA');
          }}
        />
        <span
          className="map__options__chartTitle"
          onClick={() => {
            props.onChartOptionsFirst('REA');
            metrics.collect(metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.CARTA, 'REA');
          }}
        >
          REA
        </span>
      </span>
      <span className="map__options__item">
        <CheckBox
          defaultChecked={props.chartOptionsFirst === 'REH'}
          value="REH"
          onCheck={() => {
            props.onChartOptionsFirst('REH');
            metrics.collect(metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.CARTA, 'REH');
          }}
        />
        <span
          className="map__options__chartTitle"
          onClick={() => {
            props.onChartOptionsFirst('REH');
            metrics.collect(metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.CARTA, 'REH');
          }}
        >
          REH
        </span>
      </span>
      <span className="map__options__item">
        <CheckBox
          defaultChecked={props.chartOptionsFirst === 'ARC'}
          value="REH"
          onCheck={() => {
            props.onChartOptionsFirst('ARC');
            metrics.collect(metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.CARTA, 'ARC');
          }}
        />
        <span
          className="map__options__chartTitle"
          onClick={() => {
            props.onChartOptionsFirst('ARC');
            metrics.collect(metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.CARTA, 'ARC');
          }}
        >
          ARC
        </span>
      </span>

      <span className="map__options__item" id="breaker" />

      <span className="map__options__item">
        <CheckBox
          defaultChecked={props.chartOptionsSecond === 'WAC'}
          value="WAC"
          onCheck={() => {
            props.onChartOptionsSecond('WAC');
            metrics.collect(metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.CARTA, 'WAC');
          }}
        />
        <span
          className="map__options__chartTitle"
          onClick={() => {
            props.onChartOptionsSecond('WAC');
            metrics.collect(metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.CARTA, 'WAC');
          }}
        >
          WAC
        </span>
      </span>
      <span className="map__options__item">
        <CheckBox
          defaultChecked={props.chartOptionsSecond === 'ENRCH'}
          value="ENRCH"
          onCheck={() => {
            props.onChartOptionsSecond('ENRCH');
            metrics.collect(metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.CARTA, 'ENRC_HIGH');
            tourContext.dispatchTourClick(metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.CARTA);
          }}
        />
        <span
          className="map__options__chartTitle"
          onClick={() => {
            props.onChartOptionsSecond('ENRCH');
            metrics.collect(metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.CARTA, 'ENRC_HIGH');
            tourContext.dispatchTourClick(metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.CARTA);
          }}
        >
          ENRC High
        </span>
      </span>
      <span className="map__options__item">
        <CheckBox
          defaultChecked={props.chartOptionsSecond === 'ENRCL'}
          value="ENRCL"
          onCheck={() => {
            props.onChartOptionsSecond('ENRCL');
            metrics.collect(metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.CARTA, 'ENRC_LOW');
          }}
        />
        <span
          className="map__options__chartTitle"
          onClick={() => {
            props.onChartOptionsSecond('ENRCL');
            metrics.collect(metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.CARTA, 'ENRC_LOW');
          }}
        >
          ENRC Low
        </span>
      </span>

      <h1 className="map__options__subtitle">mapa base</h1>

      <span className="map__options__item">
        <CheckBox
          defaultChecked={props.baseMap === 'ROADMAP'}
          onCheck={() => {
            props.onBaseMapChange('ROADMAP');
            metrics.collect(metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.CARTA, 'RODOVIARIO');
          }}
        />
        <span
          className="map__options__chartTitle"
          onClick={() => {
            props.onBaseMapChange('ROADMAP');
            metrics.collect(metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.CARTA, 'RODOVIARIO');
          }}
        >
          Rodoviário
        </span>
      </span>
      <span className="map__options__item">
        <CheckBox
          defaultChecked={props.baseMap === 'SATELLITE'}
          value="SATELLITE"
          onCheck={() => {
            props.onBaseMapChange('SATELLITE');
            metrics.collect(metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.CARTA, 'SATELITE');
          }}
        />
        <span
          className="map__options__chartTitle"
          onClick={() => {
            props.onBaseMapChange('SATELLITE');
            metrics.collect(metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.CARTA, 'SATELITE');
          }}
        >
          Satélite
        </span>
      </span>
      <span className="map__options__item">
        <CheckBox
          defaultChecked={props.baseMap === 'HYBRID'}
          value="HYBRID"
          onCheck={() => {
            props.onBaseMapChange('HYBRID');
            metrics.collect(metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.CARTA, 'SATELITE_LUGARES');
          }}
        />
        <span
          className="map__options__chartTitle"
          onClick={() => {
            props.onBaseMapChange('HYBRID');
            metrics.collect(metrics.events.MENU_MAPA.CARTAS_AERONAUTICAS.CARTA, 'SATELITE_LUGARES');
          }}
        >
          Satélite (com lugares)
        </span>
      </span>
    </div>
  );
};

export default MapOptions;
