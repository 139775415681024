import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';

import './Drawer.style.scss';

import Icon from 'react-uwp/Icon';

import { TourContext } from 'context/TourContext/TourContext';

import mostrarMapas from 'assets/icons/barras/mostrarMapas.svg';
import planejarRotas from 'assets/icons/barras/planejarRotas.svg';
import logoHorizontal from 'assets/icons/barras/logoHorizontal.svg';
import application from 'assets/icons/barras/aplicativo.svg';
import applicationWithNotification from 'assets/icons/barras/aplicativoNotificacao.svg';
import rotasFavoritas from 'assets/icons/barras/rotasFavoritas.svg';
import nexMarket from 'assets/icons/barras/nexmarket.svg';
import news from 'assets/icons/barras/news.svg';
import newsActive from 'assets/icons/barras/news_active.svg';
import hamburguer from 'assets/icons/barras/hamburguer.svg';

import mostrarMapasDisabled from 'assets/icons/barras/mostrarMapasDisabled.svg';
import planejarRotasDisabled from 'assets/icons/barras/planejarRotasDisabled.svg';
import rotasFavoritasDisabled from 'assets/icons/barras/rotasFavoritasDisabled.svg';
import nexMarketDisabled from 'assets/icons/barras/nexmarketDisabled.svg';

import { getLastNewsId } from 'redux/selectors/news';

import classnames from 'classnames';
import metrics from '../../../../utils/metrics';

class Drawer extends Component {
  state = {
    appNotification: true,
  };

  shouldComponentUpdate(nextProps) {
    const { appState, newsState, newsLastId, disabled, drawer } = this.props;

    return (
      nextProps.drawer.open !== drawer.open ||
      nextProps.drawer.drawerRoute !== drawer.drawerRoute ||
      nextProps.appState.loading.size !== appState.loading.size ||
      nextProps.newsState.lastNewsId !== newsState.lastNewsId ||
      nextProps.newsLastId !== newsLastId ||
      nextProps.disabled !== disabled
    );
  }

  render() {
    const { appState, drawer, newsState, newsLastId, disabled } = this.props;
    const { appNotification } = this.state;

    if (drawer.drawerRoute === '/app') {
      this.setState({ appNotification: false });
    }

    return (
      <div className={classnames('main-drawer', { open: drawer.open })}>
        <div className="main-drawer__container-top">
          <div
            className={classnames('main-drawer__icon', { open: drawer.open, 'has-tooltip': true })}
            onClick={() => {
              drawer.toggleDrawer();
              metrics.collect(metrics.events.DRAWER.HAMBURGUER);
            }}
          >
            <img className="main-drawer__icon__map" src={hamburguer} width={20} height={25} alt="hamburguer" />
            <div className={classnames('main-drawer__icon-label', { active: false })}>
              <img className="main-drawer__icon__nexAtlasIcoHorizontal" src={logoHorizontal} height={18} alt="logoNexAtlas" />
            </div>
          </div>

          <div
            className={classnames('main-drawer__icon', {
              active: drawer.drawerRoute === '/' && !disabled,
              'has-tooltip': true,
            })}
            onClick={() => {
              metrics.collect(metrics.events.DRAWER.MOSTRAR_MAPA);
              return !disabled && drawer.setDrawerRoute('/');
            }}
          >
            <span className="main-drawer__icon__tooltipMap">Mostrar Mapa</span>
            <img className="main-drawer__icon__map" src={disabled ? mostrarMapasDisabled : mostrarMapas} width={20} height={20} alt="logoMap" />
            <div className={classnames('main-drawer__icon-label', { active: drawer.drawerRoute === '/' })}>Mostrar Mapa</div>
          </div>

          <div
            className={classnames('main-drawer__icon', {
              active: drawer.drawerRoute === '/route-plan',
              'has-tooltip': true,
            })}
            onClick={() => {
              if (disabled) {
                return;
              }
              drawer.drawerRoute !== '/route-plan' ? drawer.setDrawerRoute('/route-plan') : drawer.setDrawerRoute('/');
              metrics.collect(metrics.events.DRAWER.PLANEJAR_ROTA.THIS);
            }}
          >
            <span className="main-drawer__icon__tooltipPlan">Planejar rota</span>
            <img className="main-drawer__icon__plan" src={disabled ? planejarRotasDisabled : planejarRotas} width={20} height={20} alt="logoPlan" />
            <div className={classnames('main-drawer__icon-label', { active: drawer.drawerRoute === '/route-plan' })}>Planejar Rota</div>
          </div>

          <TourContext.Consumer>
            {tourContext => (
              <div
                className={classnames('main-drawer__icon', {
                  active: drawer.drawerRoute === '/route-favorites',
                  'has-tooltip': true,
                })}
                id="rotas_salvas"
                onClick={() => {
                  if (disabled) {
                    return;
                  }
                  drawer.drawerRoute !== '/route-favorites' ? drawer.setDrawerRoute('/route-favorites') : drawer.setDrawerRoute('/');
                  metrics.collect(metrics.events.DRAWER.ROTAS_SALVAS.THIS);
                  tourContext.dispatchTourClick(metrics.events.DRAWER.ROTAS_SALVAS.THIS);
                }}
              >
                <span className="main-drawer__icon__tooltipHistory">Rotas Salvas</span>
                <img
                  className="main-drawer__icon__history"
                  src={disabled ? rotasFavoritasDisabled : rotasFavoritas}
                  width={20}
                  height={20}
                  alt="rotasFavoritas"
                />
                <div
                  className={classnames('main-drawer__icon-label', {
                    active: drawer.drawerRoute === '/route-favorites',
                  })}
                >
                  Rotas Salvas
                </div>
              </div>
            )}
          </TourContext.Consumer>
          <div
            className={classnames('main-drawer__icon', {
              active: drawer.drawerRoute === '/market',
              'has-tooltip': true,
            })}
            id="servicos"
            onClick={() => {
              drawer.drawerRoute !== '/market' ? drawer.setDrawerRoute('/market') : drawer.setDrawerRoute('/');
            }}
          >
            <span className="main-drawer__icon__tooltipMarket">Serviços</span>
            <img className="main-drawer__icon__market" src={disabled ? nexMarketDisabled : nexMarket} width={20} height={20} alt="servicos" />
            <div
              className={classnames('main-drawer__icon-label', {
                active: drawer.drawerRoute === '/market',
              })}
            >
              Serviços
            </div>
          </div>
        </div>
        <div className="main-drawer__container-bottom">
          {appState.loading.size > 0 && (
            <div className={classnames('main-drawer__icon', { active: drawer.drawerRoute === '/feedback' })}>
              <Loader type="TailSpin" color="#00B96D" height="22" width="22" />
            </div>
          )}

          <div
            className={classnames('main-drawer__icon', {
              active: drawer.drawerRoute === '/app',
              'has-tooltip': true,
            })}
            onClick={() => {
              drawer.drawerRoute !== '/app' ? drawer.setDrawerRoute('/app') : drawer.setDrawerRoute('/');
              metrics.collect(metrics.events.DRAWER.APLICATIVO.THIS);
              this.setState({ appNotification: false });
            }}
          >
            <span className="main-drawer__icon__tooltipHistory">Aplicativo</span>
            <img
              className="main-drawer__icon__app"
              src={appNotification && drawer.drawerRoute !== '/app' ? applicationWithNotification : application}
              width={20}
              height={20}
              alt=""
            />
            <div className={classnames('main-drawer__icon-label', { active: drawer.drawerRoute === '/app' })}>Aplicativo</div>
          </div>

          <div
            className={classnames('main-drawer__icon', {
              active: drawer.drawerRoute === '/news',
              'has-tooltip': true,
            })}
            onClick={() => {
              drawer.drawerRoute !== '/news' ? drawer.setDrawerRoute('/news') : drawer.setDrawerRoute('/');
              metrics.collect(metrics.events.DRAWER.NOVIDADES);
            }}
          >
            <span className="main-drawer__icon__tooltipHistory">Novidades</span>
            <img className="main-drawer__icon__news" src={newsState.lastNewsId < newsLastId ? newsActive : news} width={20} height={20} alt="" />
            <div
              className={classnames('main-drawer__icon-label', {
                active: drawer.drawerRoute === '/news',
              })}
            >
              Novidades
            </div>
          </div>

          {/* <div
            className={classnames('main-drawer__icon', {
              active: drawer.drawerRoute === 'FEEDBACK',
              'has-tooltip': true,
            })}
            onClick={() => {
              drawer.drawerRoute !== 'FEEDBACK' ? drawer.setDrawerRoute('FEEDBACK') : drawer.setDrawerRoute(null);
              metrics.collect(metrics.events.DRAWER.FALE_CONOSCO.THIS);
            }}
          >
            <span className="main-drawer__icon__tooltipFeedback">Fale conosco</span>
            <img className="main-drawer__icon__faq" src={faleConosco} width={20} height={20} alt="logoFAQ" />
            <div className={classnames('main-drawer__icon-label', { active: drawer.drawerRoute === 'FEEDBACK' })}>Fale conosco</div>
          </div> */}

          <div
            className={classnames('main-drawer__icon', {
              active: drawer.drawerRoute === '/configs',
              'has-tooltip': true,
            })}
            onClick={() => {
              drawer.drawerRoute !== '/configs' ? drawer.setDrawerRoute('/configs') : drawer.setDrawerRoute('/');
              metrics.collect(metrics.events.DRAWER.CONFIGURACOES.THIS);
            }}
          >
            <span className="main-drawer__icon__tooltipConfig">Configurações</span>
            <Icon className="main-drawer__icon__config">Settings</Icon>

            <div className={classnames('main-drawer__icon-label', { active: drawer.drawerRoute === '/configs' })}>Configurações</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatch = {};

const mapStateToProps = (state, props) => ({
  appState: state.appState,
  newsState: state.news,
  newsLastId: getLastNewsId(state, props),
});

export default connect(mapStateToProps, mapDispatch)(Drawer);
