import React from 'react';
import PropTypes from 'prop-types';

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';

import MaterialInput from 'components/form/MaterialInput/MaterialInput';
import InlineButton from 'components/form/InlineButton/InlineButton';

const ContentLoginUser = props => {
  const { email, error, onChangeEmail, onFacebookFinish, onGoogleFinish, onCreateAccount, onEnter } = props;

  return (
    <div>
      <MaterialInput
        name="email"
        label="Digite seu e-mail"
        value={email}
        info={error}
        infoLevel={error && 'warning'}
        infoMargin
        onChangeValue={onChangeEmail}
        onEnter={onEnter}
      />
      <p>
        ou, acesse pelo{' '}
        <FacebookLogin
          appId="390385938195449"
          callback={onFacebookFinish}
          fields="first_name,last_name,middle_name,email,picture"
          language="pt_BR"
          render={renderProps => <InlineButton onClick={renderProps.onClick}>Facebook</InlineButton>}
        />{' '}
        ou{' '}
        <GoogleLogin
          clientId="718555250810-5476j41le71nreeta7sefeeb05im0fjp.apps.googleusercontent.com"
          render={renderProps => <InlineButton onClick={renderProps.onClick}>Google</InlineButton>}
          buttonText="Login"
          onSuccess={onGoogleFinish}
          onFailure={() => {}}
          cookiePolicy="single_host_origin"
        />
      </p>
      <p>
        Não tem uma conta? <InlineButton onClick={onCreateAccount}>Crie a sua!</InlineButton>
      </p>
    </div>
  );
};

ContentLoginUser.defaultProps = {
  error: null,
  onChangeEmail: () => {},
  onFacebookFinish: () => {},
  onGoogleFinish: () => {},
  onCreateAccount: () => {},
  onEnter: () => {},
};

ContentLoginUser.propTypes = {
  email: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChangeEmail: PropTypes.func,
  onFacebookFinish: PropTypes.func,
  onGoogleFinish: PropTypes.func,
  onCreateAccount: PropTypes.func,
  onEnter: PropTypes.func,
};

export default ContentLoginUser;
