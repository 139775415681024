import React from 'react';

import Button from 'react-uwp/Button';

import metrics from '../../../../../../utils/metrics';

import CustomScrollbar from 'components/CustomScrollbar/CustomScrollbar';

import './ConfigsPaymentEnd.styles.scss';

export default props => {
  const { method, loading } = props;

  return (
    <div className="configs__base">
      <div className="configs__title">ASSINATURA ATIVADA</div>
      <CustomScrollbar className="configs__scroll">
        <div className="configs__container">
          <div className="configs__container__session">
            <p className="configs__container__paragraph">Seja-bem vindo(a) ao grupo de assinantes NexAtlas!</p>
            {method === 'boleto' && (
              <p className="configs__container__paragraph">
                Um boleto bancário acaba de ser enviado para o seu e-mail. Realize o pagamento antes do vencimento para manter a sua assinatura ativa.
              </p>
            )}
            <p className="configs__container__paragraph">
              Agora as suas sugestões possuem prioridade nos aprimoramentos previstos para este aplicativo! Sempre que tiver algo a dizer, envie-nos
              facilmente uma mensagem pelo painel Enviar comentários.
            </p>
            <p className="configs__container__paragraph">
              Caso venha a desejar realizar algum ajuste ou cancelar a sua assinatura, utilize as opções disponíveis no painel Configurações.
            </p>
            <p className="configs__container__paragraph">Muito obrigado e bom proveito!</p>
            <div className="configs__trial-end__button">
              <Button
                tooltip="Assine agora a NexAtlas"
                className="user-options__logout__button"
                onClick={() => {
                  metrics.collect(metrics.events.DRAWER.CONFIGURACOES.ASSINE_JA.INICIAR);
                  props.onFinish();
                }}
                disabled={loading}
              >
                Iniciar
              </Button>
            </div>
          </div>
        </div>
      </CustomScrollbar>
    </div>
  );
};
