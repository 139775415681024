/* global mt */
/* global gtag */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import AmplitudeClient from 'amplitude-js';
import LinkedInTag from 'react-linkedin-insight';

import { wrapComponent as withSnackbar } from 'react-snackbar-alert';

import { getLastUpdate, getUpdateItems } from 'redux/selectors/data_update';
import { getPlans, getUserPlan } from 'redux/selectors/plan';
import {
  setTrialChecked,
  setSubscriptionChecked,
  createPaymentAccount,
  updatePaymentAccount,
  cancelPaymentAccount,
  recoveryPassword,
  setUserFlag,
  logout,
} from 'redux/actions/authActions';

import { maskTypingCPF, maskTypingCNPJ, maskTypingCEP, maskTypingPhone, addZeroPad } from 'utils/masks';
import moment from 'moment';

import { STATUS as PAYMENT_STATUS } from 'components/CheckPayment/CheckPayment';
import ConfigsMain from './components/ConfigsMain/ConfigsMain';
import ConfigsPasswordSent from './components/ConfigsPasswordSent/ConfigsPasswordSent';
import ConfigsMoreInfo from './components/ConfigsMoreInfo/ConfigsMoreInfo';
import ConfigsUserWaypoints from './components/ConfigsUserWaypoints/ConfigsUserWaypoints';
import ConfigsUserWaypointManager from './components/ConfigsUserWaypointManager/ConfigsUserWaypointManager';
import ConfigsTerms from './components/ConfigsTerms/ConfigsTerms';
import ConfigsTrialCheck from './components/ConfigsTrialCheck/ConfigsTrialCheck';
import ConfigsTrialEnd from './components/ConfigsTrialEnd/ConfigsTrialEnd';
import ConfigsPlans from './components/ConfigsPlans/ConfigsPlans';
import ConfigsPaymentMethod from './components/ConfigsPaymentMethod/ConfigsPaymentMethod';
import ConfigsCreditCard from './components/ConfigsCreditCard/ConfigsCreditCard';
import ConfigsPaymentData from './components/ConfigsPaymentData/ConfigsPaymentData';
import ConfigsTrialFinalize from './components/ConfigsTrialFinalize/ConfigsTrialFinalize';
import ConfigsPaymentEnd from './components/ConfigsPaymentEnd/ConfigsPaymentEnd';
import ConfigsCancel from './components/ConfigsCancel/ConfigsCancel';
import ConfigsManageDependants from './components/ConfigsManageDependants/ConfigsManageDependants';
import ConfigsDependantStart from './components/ConfigsDependantStart/ConfigsDependantStart';
import ConfigsDependantEnd from './components/ConfigsDependantEnd/ConfigsDependantEnd';

import './Configs.style.scss';

const Configs = props => {
  const { lastUpdate, updateItems, plans, token, userPlan, user, userProfile, fixedStatus, onClick } = props;
  const history = useHistory();

  const urlScreen = history.location.pathname.replace(/\/dashboard(\/)?([^/]+)?(\/)?/, '').toUpperCase();

  const [screen, setScreen] = useState(urlScreen || fixedStatus || 'MAIN');
  const [userWaypointId, setUserWaypointId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [lastFixedStatus, setLastFixedStatus] = useState(null);

  const [wizardPlan, setWizardPlan] = useState(userPlan || null);
  const [wizardMethod, setWizardMethod] = useState((user && user.subscription_method !== '' && user.subscription_method) || 'credit_card');
  const [wizardCreditCard, setWizardCreditCard] = useState(null);
  const [wizardPaymentData, setWizardPaymentData] = useState(null);

  if (userProfile && !wizardPaymentData) {
    const bornAt = moment(userProfile.bornAt);

    setWizardPaymentData({
      name: `${userProfile.name} ${userProfile.surname}`,
      address: userProfile.address && userProfile.address.toString(),
      streetNumber: userProfile.street_number && userProfile.street_number.toString(),
      complementary: userProfile.complementary && userProfile.complementary.toString(),
      neighborhood: userProfile.neighborhood && userProfile.neighborhood.toString(),
      phoneDdd: userProfile.phone_ddd && userProfile.phone_ddd.toString(),
      phoneNumber: userProfile.phone_number && maskTypingPhone(userProfile.phone_number.toString()),
      cep: userProfile.zipcode && maskTypingCEP(addZeroPad(userProfile.zipcode.toString(), 8)),
      cpf:
        userProfile.cpf &&
        (String(userProfile.cpf).length <= 11
          ? maskTypingCPF(addZeroPad(userProfile.cpf.toString(), 11))
          : maskTypingCNPJ(addZeroPad(userProfile.cpf.toString(), 14))),
      bornAtDay: bornAt && { value: bornAt.format('DD'), label: bornAt.format('DD') },
      bornAtMonth: bornAt && { value: bornAt.format('MM'), label: bornAt.format('MM') },
      bornAtYear: bornAt && { value: bornAt.format('YYYY'), label: bornAt.format('YYYY') },
    });
  }

  useEffect(() => {
    ReactGA.modalview('/drawer/configs');
    mt('send', 'pageview', { email: user.email });
  }, [user.email]);

  useEffect(() => {
    ReactGA.modalview(`/drawer/configs/${screen}`);

    if (screen === 'PAYMENT_PLANS') {
      ReactPixel.track('AddToCart', {});
      gtag('event', 'conversion', { send_to: 'AW-529361823/SU13CNaIyvsBEJ_XtfwB' });
      LinkedInTag.track('4592297');
    }
  }, [screen]);

  useEffect(() => {
    if (fixedStatus && fixedStatus !== lastFixedStatus && !urlScreen) {
      setLastFixedStatus(fixedStatus);
      setScreen(fixedStatus);
    }
  }, [fixedStatus, lastFixedStatus, urlScreen]);

  const isWizard = true;

  const setTrialChecked = () => {
    setLoading(true);

    ReactPixel.track('StartTrial', {});
    LinkedInTag.track('4592289');

    props
      .setTrialChecked()
      .then(response => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const setPlan = plan => {
    if (isWizard) {
      setWizardPlan(plan);
      setScreen('PAYMENT_METHOD');
    }
  };

  const setMethod = method => {
    if (isWizard) {
      setWizardMethod(method);

      ReactPixel.track('AddPaymentInfo', {});
      LinkedInTag.track('4613705');

      if (method === 'credit_card') {
        setScreen('PAYMENT_CREDIT_CARD');
      } else {
        setScreen('PAYMENT_DATA');
      }
    }
  };

  const setCreditCard = creditCard => {
    if (isWizard) {
      setWizardCreditCard(creditCard);
      setScreen('PAYMENT_DATA');
    }
  };

  const setPaymentData = paymentData => {
    if (isWizard) {
      setWizardPaymentData(paymentData);
      ReactPixel.track('InitiateCheckout', {});
      gtag('event', 'conversion', { send_to: 'AW-529361823/NpbQCNXHqfsBEJ_XtfwB' });
      setScreen('PAYMENT_FINALIZE');
    }
  };

  const startSubscriptionChange = () => {
    setScreen('PAYMENT_PLANS');
  };

  const wizardFinalize = () => {
    setError(null);
    setLoading(true);

    let promisse = null;
    if (user.subscription_id && user.subscription_status !== 'canceled' && user.subscription_status !== 'ended') {
      promisse = props.updatePaymentAccount(user.subscription_id, wizardPlan, wizardMethod, wizardCreditCard, wizardPaymentData);
    } else {
      promisse = props.createPaymentAccount(wizardPlan, wizardMethod, wizardCreditCard, wizardPaymentData);
    }

    promisse
      .then(response => {
        setLoading(false);

        let message = null;
        if (response.payload && response.payload.data && response.payload.data.message) {
          message = response.payload.data.message;
        } else {
          ReactPixel.track('Subscribe', { currency: 'BRL', value: wizardPlan.price });

          const amplitudeRevenue = new AmplitudeClient.Revenue()
            .setQuantity(1)
            .setProductId(`${wizardPlan.id}`)
            .setPrice(wizardPlan.price);
          AmplitudeClient.getInstance().logRevenueV2(amplitudeRevenue);

          gtag('event', 'conversion', {
            send_to: 'AW-529361823/U-k9CLnivvsBEJ_XtfwB',
            value: wizardPlan.price,
            currency: 'BRL',
          });

          LinkedInTag.track('4613713');

          if (user.subscription_checked) {
            setScreen('MAIN');
          } else {
            setScreen(PAYMENT_STATUS.SUBSCRIPTION_FINISHED);
          }
          return;
        }

        switch (message) {
          case 'invalidZipcode':
            setScreen('PAYMENT_DATA');
            setError('INVALID_ZIPCODE');
            return;

          case 'errorCreditCard':
            setScreen('PAYMENT_CREDIT_CARD');
            setError('INVALID_CREDIT_CARD');
            return;

          default:
            setError('UNKNOWN');
        }
      })
      .catch(() => {
        setLoading(false);
        setError('UNKNOWN');
      });
  };

  const wizardEnd = () => {
    setLoading(true);

    props
      .setSubscriptionChecked()
      .then(response => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleChangePassword = () => {
    props
      .recoveryPassword(user.email)
      .then(resp => {
        setScreen('PASSWORD_SENT');
      })
      .catch(() => {
        props.createSnackbar({
          message: 'Erro ao alterar a senha, tente novamente mais tarde.',
        });
      });
  };

  return (
    <div className="configs" onClick={onClick}>
      {(screen === 'MAIN' || screen === PAYMENT_STATUS.NEED_PAYMENT) && (
        <ConfigsMain
          user={user}
          userProfile={userProfile}
          userPlan={userPlan}
          lastUpdate={lastUpdate}
          onMoreInfo={() => setScreen('MORE_INFO')}
          onUserWaypoints={() => setScreen('USER_WAYPOINTS')}
          onTerms={() => setScreen('TERMS')}
          onManageDependants={() => setScreen('MANAGE_DEPENDANTS')}
          onChangeSubscription={() => startSubscriptionChange()}
          onCancelSubscription={() => setScreen('CANCEL')}
          onChangePassword={() => handleChangePassword()}
          onLogout={() => props.logout(token)}
        />
      )}
      {screen === 'PASSWORD_SENT' && <ConfigsPasswordSent onReturn={() => setScreen('MAIN')} />}
      {screen === 'MORE_INFO' && <ConfigsMoreInfo updateItems={updateItems} onReturn={() => setScreen('MAIN')} />}
      {screen === 'USER_WAYPOINTS' && (
        <ConfigsUserWaypoints
          onManage={id => {
            setUserWaypointId(id);
            setScreen('MANAGE_USER_WAYPOINT');
          }}
          onReturn={() => setScreen('MAIN')}
        />
      )}
      {screen === 'MANAGE_USER_WAYPOINT' && (
        <ConfigsUserWaypointManager userWaypointId={userWaypointId} onLocate={props.onLocate} onReturn={() => setScreen('USER_WAYPOINTS')} />
      )}

      {screen === 'TERMS' && <ConfigsTerms onReturn={() => setScreen('MAIN')} />}
      {screen === 'CANCEL' && <ConfigsCancel onCancelPaymentAccount={props.cancelPaymentAccount} onReturn={() => setScreen('MAIN')} />}
      {screen === 'MANAGE_DEPENDANTS' && <ConfigsManageDependants user={user} onReturn={() => setScreen('MAIN')} />}
      {screen === PAYMENT_STATUS.DEPENDANT_START && (
        <ConfigsDependantStart
          user={user}
          userProfile={userProfile}
          loading={loading}
          onCheck={() => props.setUserFlag('MESSAGE_DEPENDANT_START', 'HIDE')}
          onLogout={() => props.logout(token)}
        />
      )}
      {screen === PAYMENT_STATUS.DEPENDANT_END && (
        <ConfigsDependantEnd
          userProfile={userProfile}
          loading={loading}
          onCheck={() => props.setUserFlag('MESSAGE_DEPENDANT_END', 'HIDE')}
          onLogout={() => props.logout(token)}
        />
      )}
      {screen === PAYMENT_STATUS.TRIAL_CHECK && (
        <ConfigsTrialCheck userProfile={userProfile} loading={loading} onCheck={() => setTrialChecked()} onLogout={() => props.logout(token)} />
      )}
      {screen === PAYMENT_STATUS.TRIAL_ENDED && (
        <ConfigsTrialEnd userProfile={userProfile} onCheck={() => setScreen('PAYMENT_PLANS')} onLogout={() => props.logout(token)} />
      )}
      {screen === 'PAYMENT_PLANS' && (
        <ConfigsPlans
          plans={plans}
          values={{ planOption: wizardPlan }}
          user={user}
          onFinish={setPlan}
          onReturn={() => {
            if (!user.subscription_id && moment(user.trialTo) < moment()) {
              setScreen(PAYMENT_STATUS.TRIAL_ENDED);
            } else {
              setScreen('MAIN');
            }
          }}
          single={!isWizard}
        />
      )}
      {screen === 'PAYMENT_METHOD' && (
        <ConfigsPaymentMethod
          values={{ methodOption: wizardMethod }}
          user={user}
          onFinish={setMethod}
          onReturn={() => setScreen('PAYMENT_PLANS')}
          single={!isWizard}
        />
      )}
      {screen === 'PAYMENT_CREDIT_CARD' && (
        <ConfigsCreditCard
          values={wizardCreditCard}
          onFinish={setCreditCard}
          onReturn={() => setScreen('PAYMENT_METHOD')}
          single={!isWizard}
          error={error}
        />
      )}
      {screen === 'PAYMENT_DATA' && (
        <ConfigsPaymentData
          values={wizardPaymentData}
          user={user}
          onFinish={setPaymentData}
          onReturn={() => {
            if (wizardMethod === 'credit_card') {
              setScreen('PAYMENT_CREDIT_CARD');
            } else {
              setScreen('PAYMENT_METHOD');
            }
          }}
          single={!isWizard}
          error={error}
        />
      )}
      {screen === 'PAYMENT_FINALIZE' && (
        <ConfigsTrialFinalize
          plan={wizardPlan}
          method={wizardMethod}
          card={wizardCreditCard}
          paymentData={wizardPaymentData}
          loading={loading}
          onReturnToPlan={() => setScreen('PAYMENT_PLANS')}
          onReturnToMethod={() => setScreen('PAYMENT_METHOD')}
          onReturnToPaymentData={() => setScreen('PAYMENT_DATA')}
          onReturn={() => setScreen('PAYMENT_DATA')}
          onFinish={wizardFinalize}
          error={error}
        />
      )}
      {screen === PAYMENT_STATUS.SUBSCRIPTION_FINISHED && <ConfigsPaymentEnd method={wizardMethod} onFinish={wizardEnd} disabled={loading} />}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  lastUpdate: getLastUpdate(state, props),
  updateItems: getUpdateItems(state, props),
  plans: getPlans(state, props),
  userPlan: getUserPlan(state, props),
  token: state.auth.token,
  user: state.auth.user,
  userProfile: state.auth.user_profile,
});

const mapDispatchToProps = {
  setTrialChecked,
  setSubscriptionChecked,
  createPaymentAccount,
  updatePaymentAccount,
  cancelPaymentAccount,
  recoveryPassword,
  setUserFlag,
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Configs));
