import { AUTH } from './types';

export function login(email, password, rememberMe) {
  return {
    type: AUTH.LOGIN,
    payload: {
      client: 'login',
      request: {
        url: '/user/authenticate',
        method: 'post',
        data: {
          email,
          password,
          rememberMe,
          source: 'webapp',
        },
      },
    },
  };
}

export function facebookLogin(first_name, last_name, email, facebook_id, access_token) {
  return {
    type: AUTH.FB_LOGIN,
    payload: {
      client: 'login',
      request: {
        url: '/user/facebookAuthenticate',
        method: 'post',
        data: {
          email,
          facebook_id,
          access_token,
          name: first_name,
          surname: last_name,
          facebook: true,
          picture: null,
          source: 'webapp',
        },
      },
    },
  };
}

export function googleLogin(first_name, last_name, email, google_id, id_token) {
  return {
    type: AUTH.GOOGLE_LOGIN,
    payload: {
      client: 'login',
      request: {
        url: '/user/googleAuthenticate',
        method: 'post',
        data: {
          email,
          google_id,
          id_token,
          name: first_name,
          surname: last_name,
          google: true,
          picture: null,
          source: 'webapp',
        },
      },
    },
  };
}

export function recoveryPassword(email) {
  return {
    type: AUTH.RECOVERY_PASSWORD,
    payload: {
      client: 'login',
      request: {
        url: '/user/lostPasswordRequest',
        method: 'post',
        data: {
          email,
        },
      },
    },
  };
}

export function userCreate(email, password, captcha) {
  return {
    type: AUTH.CREATE,
    payload: {
      client: 'login',
      request: {
        url: '/user/create',
        method: 'post',
        data: {
          email,
          password,
          captcha,
        },
      },
    },
  };
}

export function userProfileCreate(
  userID,
  type,
  pilot,
  pilotProfessional,
  name,
  surname,
  phoneDDD,
  phoneNumber,
  aircraftType,
  aviationSegment,
  selfClassification,
  document,
  terms,
) {
  return {
    type: AUTH.PROFILE_CREATE,
    payload: {
      client: 'login',
      request: {
        url: '/userProfile/create',
        method: 'post',
        data: {
          type,
          pilot,
          name,
          surname,
          phone_ddd: phoneDDD,
          phone_number: phoneNumber,
          user_id: userID,
          pilot_professional: pilotProfessional,
          aircraft_type: aircraftType,
          aviation_segment: aviationSegment,
          self_classification: selfClassification,
          cpf: document,
          read_terms: terms,
          source: 'webapp',
        },
      },
    },
  };
}

export function refreshUser() {
  return {
    type: AUTH.UPDATE_USER,
    payload: {
      client: 'api',
      request: {
        url: '/user/getCurrentUser',
        method: 'post',
        data: {},
      },
    },
  };
}

export function registerAccess(path) {
  return {
    type: AUTH.REGISTER_ACCESS,
    payload: {
      client: 'api',
      request: {
        url: '/user/registerAccess',
        method: 'post',
        data: {
          path,
        },
      },
    },
  };
}

export function setTrialChecked() {
  return {
    type: AUTH.UPDATE_USER,
    payload: {
      client: 'api',
      request: {
        url: '/user/setTrialChecked',
        method: 'post',
        data: {},
      },
    },
  };
}

export function createPaymentAccount(plan, method, creditCard, paymentData) {
  let sendData = {
    plan_id: plan.id,
    payment_method: method,
    name: paymentData.name,
    cpf: paymentData.cpf
      .replace(/\./g, '')
      .replace(/-/g, '')
      .replace(/\//g, ''),
    address: paymentData.address,
    street_number: paymentData.streetNumber,
    complementary: paymentData.complementary,
    neighborhood: paymentData.neighborhood,
    zipcode: paymentData.cep.replace(/-/g, ''),
    phone_ddd: paymentData.phoneDdd,
    phone_number: paymentData.phoneNumber.replace(/-/g, ''),
    born_at: `${paymentData.bornAtYear.value}-${paymentData.bornAtMonth.value}-${paymentData.bornAtDay.value}`,
  };

  if (creditCard) {
    sendData = {
      ...sendData,
      card_number: creditCard.number,
      card_name: creditCard.name,
      card_valid: creditCard.validMonth.value + creditCard.validYear.value,
      card_cvv: creditCard.csv,
    };
  }

  return {
    type: AUTH.UPDATE_USER,
    payload: {
      client: 'api',
      request: {
        url: '/user/createPaymentAccount',
        method: 'post',
        data: sendData,
      },
    },
  };
}

export function updatePaymentAccount(subscription_id, plan, method, creditCard, paymentData) {
  let sendData = {
    subscription_id,
    plan_id: plan.id,
    payment_method: method,
    name: paymentData.name,
    cpf: paymentData.cpf.replace(/\./g, '').replace(/-/g, ''),
    address: paymentData.address,
    street_number: paymentData.streetNumber,
    complementary: paymentData.complementary,
    neighborhood: paymentData.neighborhood,
    zipcode: paymentData.cep.replace(/-/g, ''),
    phone_ddd: paymentData.phoneDdd,
    phone_number: paymentData.phoneNumber.replace(/-/g, ''),
    born_at: `${paymentData.bornAtYear.value}-${paymentData.bornAtMonth.value}-${paymentData.bornAtDay.value}`,
  };

  if (creditCard) {
    sendData = {
      ...sendData,
      card_number: creditCard.number,
      card_name: creditCard.name,
      card_valid: creditCard.validMonth.value + creditCard.validYear.value,
      card_cvv: creditCard.csv,
    };
  }

  return {
    type: AUTH.UPDATE_USER,
    payload: {
      client: 'api',
      request: {
        url: '/user/updatePaymentAccount',
        method: 'post',
        data: sendData,
      },
    },
  };
}

export function cancelPaymentAccount(reason, text) {
  return {
    type: AUTH.UPDATE_USER,
    payload: {
      client: 'api',
      request: {
        url: '/user/cancelPaymentAccount',
        method: 'post',
        data: {
          reason,
          text,
        },
      },
    },
  };
}

export function setSubscriptionChecked() {
  return {
    type: AUTH.UPDATE_USER,
    payload: {
      client: 'api',
      request: {
        url: '/user/setSubscriptionChecked',
        method: 'post',
        data: {},
      },
    },
  };
}

export function setUserFlag(flag, value) {
  return {
    type: AUTH.SET_USER_FLAG,
    payload: {
      client: 'api',
      request: {
        url: '/user/setFlag',
        method: 'post',
        data: {
          flag,
          value,
        },
      },
    },
  };
}

export function updateLocalUser(user) {
  return {
    type: AUTH.UPDATE_LOCAL_USER,
    payload: {
      user,
    },
  };
}

export function setTokenDead() {
  return {
    type: AUTH.TOKEN_DEAD,
  };
}

export function logout(token = null) {
  if (!token) {
    return {
      type: `${AUTH.LOGOUT}_SUCCESS`,
    };
  }

  return {
    type: AUTH.LOGOUT,
    payload: {
      client: 'api',
      request: {
        url: '/user/logout',
        method: 'post',
        data: {
          token,
        },
      },
    },
  };
}
