import React from 'react';
import PropTypes from 'prop-types';

import './AuthScreen.style.scss';

const AuthScreen = props => {
  const { mainContainer, infoContainer, onTerms } = props;

  return (
    <div className="auth__background">
      <div className="auth__container-wrapper">{mainContainer}</div>
      {infoContainer && <div className="auth__info-wrapper">{infoContainer}</div>}
      <div className="auth__bottom">
        <div className="auth__bottom__terms" onClick={() => onTerms()} role="button" onKeyDown={() => onTerms()} tabIndex="0">
          Termos de uso e Política de Privacidade
        </div>
      </div>
    </div>
  );
};

AuthScreen.defaultProps = {
  infoContainer: null,
  onTerms: () => {},
};

AuthScreen.propTypes = {
  mainContainer: PropTypes.node.isRequired,
  infoContainer: PropTypes.node,
  onTerms: PropTypes.func,
};

export default AuthScreen;
