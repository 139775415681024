import React, { useState } from 'react';

import Button from 'react-uwp/Button';
import Icon from 'react-uwp/Icon';
import RadioButton from 'react-uwp/RadioButton';
import CustomScrollbar from 'components/CustomScrollbar/CustomScrollbar';

import parse from 'html-react-parser';

import metrics from '../../../../../../utils/metrics';

import './ConfigsPlans.styles.scss';

export default props => {
  const { plans, values, single, user, onReturn, onFinish } = props;

  let isCurrentPlanActive = false;
  if (values && values.planOption && plans) {
    const currentPlan = plans.find(plan => plan.id === values.planOption.id);
    if (currentPlan) {
      isCurrentPlanActive = currentPlan.active;
    }
  }

  const [planOption, setPlanOption] = useState((isCurrentPlanActive && values && values.planOption) || null);

  let specialPlan = null;
  if (user.subscription_special_plan && plans) {
    specialPlan = plans.find(plan => plan.id === user.subscription_special_plan);
  }

  return (
    <div className="configs__plans__base">
      <div className="configs__title">
        {!single && (
          <Icon onClick={() => onReturn()} className="configs__title__action">
            ChevronLeft
          </Icon>
        )}
        PLANO DE ASSINATURA
      </div>
      <CustomScrollbar className="configs__scroll">
        <div className="configs__container">
          <div className="configs__container__session">
            <p className="configs__container__paragraph configs__plans__top">Selecione uma opção:</p>

            <div className="configs__plans__session">
              <div className="configs__plans__title">PLANO INDIVIDUAL</div>
              <div className="configs__plans__description">
                Válido para essa conta, podendo acessar em um dispositivo por vez. Assinatura recorrente.
              </div>
              {specialPlan && (
                <RadioButton
                  key={specialPlan.id}
                  defaultChecked={planOption && planOption.id === specialPlan.id}
                  label={parse(specialPlan.text)}
                  onCheck={() => setPlanOption(specialPlan)}
                  className="configs__plans__radio"
                />
              )}
              {plans
                .filter(plan => plan.active && plan.dependants === 0)
                .map(plan => (
                  <RadioButton
                    key={plan.id}
                    defaultChecked={planOption && planOption.id === plan.id}
                    label={parse(plan.text)}
                    onCheck={() => setPlanOption(plan)}
                    className="configs__plans__radio"
                  />
                ))}
            </div>

            {user.type === 'PJ' && (
              <div>
                <div className="configs__plans__session">
                  <hr className="configs__plans__hr" />
                  <div className="configs__plans__title">PLANO EQUIPE</div>
                  <div className="configs__plans__description">
                    Entre em contato com nossa equipe para saber mais sobre planos especiais para empresas.
                    <br />
                    <br />
                    Para isso, envie um e-mail para contato@nexatlas.com ou entre em contato pelo chat, no canto inferior direito.
                  </div>
                </div>
              </div>
            )}
            <div className="configs__plans__button">
              <Button
                className="user-options__logout__button"
                onClick={() => {
                  onFinish(planOption);
                  metrics.collect(metrics.events.DRAWER.CONFIGURACOES.ASSINE_JA.PLANO, planOption.text);
                }}
                disabled={!planOption}
              >
                {single ? 'Alterar' : 'Próximo'}
              </Button>
            </div>
          </div>
        </div>
      </CustomScrollbar>
    </div>
  );
};
