import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import AuthPage from '../pages/Auth/AuthPage.jsx';

import GoTo from './GoTo';
import { AUTH, DASHBOARD } from './router';

const mainRouter = (auth, props) => {
  const { location } = props;

  if (auth.logged_in && !location.pathname.startsWith(`${AUTH.MAIN}/confirmEmail/`) && !location.pathname.startsWith(`${AUTH.MAIN}/recovery/`)) {
    return <GoTo location={DASHBOARD.MAIN} />;
  }

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LcnaHojAAAAACh-bwCFLpzzup_XmJvBqztPrF-1"
      language="pt-BR"
      container={{
        // optional to render inside custom element
        element: 'grecaptcha-badge-base',
        parameters: {
          badge: 'bottomleft',
          theme: 'dark',
        },
      }}
    >
      <AuthPage {...props} />
    </GoogleReCaptchaProvider>
  );
};

const AuthLayout = props => {
  const { auth } = props;

  return (
    <Switch>
      <Route path={AUTH.MAIN} render={props => mainRouter(auth, props)} />
    </Switch>
  );
};

const mapDispatch = {};
const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, mapDispatch)(AuthLayout);
