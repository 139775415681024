import React from 'react';
import PropTypes from 'prop-types';

const InlineButton = props => {
  const { children, onClick } = props;

  return (
    <span
      className="auth__container__content__link"
      onClick={() => onClick && onClick()}
      onKeyDown={e => e.keyCode === 13 && onClick()}
      role="button"
      tabIndex="0"
    >
      {children}
    </span>
  );
};

InlineButton.defaultProps = {
  onClick: null,
};

InlineButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

export default InlineButton;
