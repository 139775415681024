import React from 'react';

import Button from 'react-uwp/Button';
import Icon from 'react-uwp/Icon';
import CustomScrollbar from 'components/CustomScrollbar/CustomScrollbar';

import parse from 'html-react-parser';

import metrics from '../../../../../../utils/metrics';

import './ConfigsTrialFinalize.styles.scss';

export default props => {
  const { plan, method, card, paymentData, error, loading, onReturn, onReturnToPlan, onReturnToMethod, onReturnToPaymentData, onFinish } = props;

  return (
    <div className="configs__base">
      <div className="configs__title">
        <Icon onClick={() => onReturn()} className="configs__title__action">
          ChevronLeft
        </Icon>
        REVISE A SUA COMPRA
      </div>
      <CustomScrollbar className="configs__scroll">
        <div className="configs__container">
          <div className="configs__container__session">
            {error === 'UNKNOWN' && <div className="configs__trial-finalize__alert">Ocorreu um erro. Tente novamente mais tarde!</div>}
            <div className="configs__container__paragraph">
              Verifique se está tudo conforme desejado.
              <br />A sua compra será realizada ao confirmar.
            </div>
            <div className="configs__container__paragraph">
              <div className="configs__trial-finalize__title">ASSINATURA</div>
              <div className="configs__trial-finalize__text">{parse(plan.text)}</div>
              <div className="configs__trial-finalize__link" onClick={() => onReturnToPlan()}>
                Alterar plano
              </div>
            </div>
            <div className="configs__container__paragraph">
              <div className="configs__trial-finalize__title">FORMA DE PAGAMENTO</div>
              <div className="configs__trial-finalize__text">{method === 'credit_card' ? 'Cartão de Crédito' : 'Boleto bancário'}</div>
              {method === 'credit_card' && <div className="configs__trial-finalize__text">**** **** **** {card.number.substr(-4)}</div>}
              <div className="configs__trial-finalize__link" onClick={() => onReturnToMethod()}>
                Alterar forma de pagamento
              </div>
            </div>
            <div className="configs__container__paragraph">
              <div className="configs__trial-finalize__title">DADOS PARA A NOTA FISCAL</div>
              <div className="configs__trial-finalize__text">
                {paymentData.name}. {paymentData.cpf}
                <br />
                {paymentData.address}, {paymentData.streetNumber}
                <br />
                {paymentData.complementary} - {paymentData.neighborhood}
                <br />
                {paymentData.cep}
                <br />
                Telefone: ({paymentData.phoneDdd}) {paymentData.phoneNumber}
                {/* <br />
                Data de Nascimento: {paymentData.bornAtDay.value}/{paymentData.bornAtMonth.value}/
                {paymentData.bornAtYear.value} */}
              </div>
              <div className="configs__trial-finalize__link" onClick={() => onReturnToPaymentData()}>
                Alterar dados
              </div>
            </div>
            <div className="configs__trial-finalize__button">
              <Button
                className="user-options__logout__button"
                onClick={() => {
                  metrics.collect(metrics.events.DRAWER.CONFIGURACOES.ASSINE_JA.CONFIRMAR);
                  onFinish();
                }}
                disabled={loading}
              >
                Confirmar
              </Button>
            </div>
          </div>
        </div>
      </CustomScrollbar>
    </div>
  );
};
